/* eslint-disable no-unused-vars */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import CourseContent from "pages/LandingPages/CourseDetailsScreen/sections/CourseContent";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/courseDashboard.jpg";
import coursesApi from "../../../api/courses";
import useApi from "../../../hooks/useApi";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import { Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";

function CourseDetails() {
  let { courseId } = useParams();

  const getCoursesApi = useApi(coursesApi.getCourseById);
  useEffect(() => {
    getCoursesApi.request(courseId);
  }, []);

  const coursesData = getCoursesApi?.data?.data?.attributes;

  const enrolled = getCoursesApi?.data?.data?.is_access;

  const getLessonsLength = (lessonsArray) => {
    let lessonsCount = 0;
    lessonsArray?.map((l) => {
      lessonsCount += l?.Lessons?.length;
    });

    return lessonsCount;
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            {getCoursesApi.loading ? (
              <>
                <Skeleton
                  sx={{ bgcolor: "grey.900", mb: 2 }}
                  variant="rectangular"
                  color="white"
                  width={"60vw"}
                  height={"5vh"}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.900", mb: 2 }}
                  variant="rectangular"
                  color="white"
                  width={"40vw"}
                  height={"5vh"}
                />
              </>
            ) : (
              <MKTypography
                variant="h3"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3x1"],
                  },
                  fontSize: { xs: 32, sm: 40 },
                })}
                mb={3}
              >
                {coursesData?.Title}
              </MKTypography>
            )}

            {/* <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              Start Course
            </MKButton> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" my={8}>
          <Container>
            {getCoursesApi.loading === true ? (
              <Grid container spacing={2}>
                {[...Array(3)].map((_, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Card sx={{ maxWidth: 400 }}>
                      {/* Adjust maxWidth as needed */}
                      <Skeleton animation="wave" variant="circular" width={40} height={40} />
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton animation="wave" height={10} width="40%" />
                      <Skeleton sx={{ height: "60vh" }} animation="wave" variant="rectangular" />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : null}
            <Grid container spacing={3} alignItems="start">
              <Grid item sm={8} xs={12}>
                {coursesData?.Description ? (
                  <MKTypography variant="h4">Course Description</MKTypography>
                ) : null}
                <MKTypography variant="body2" color="black" mb={3} mt={2}>
                  {coursesData?.Description}
                </MKTypography>
              </Grid>
              <Grid item sm={4} xs={12}>
                {coursesData?.Description ? (
                  <CenteredBlogCard
                    image={
                      // eslint-disable-next-line no-undef
                      process.env.REACT_APP_STRAPI_URL.replace("/api", "") +
                      coursesData?.Featured_Image?.data?.attributes?.url
                    }
                    title="Course Includes"
                    description={`Sections: ${
                      coursesData?.Sections?.data?.length
                    } and Lessons: ${getLessonsLength(coursesData?.Sections?.data)}`}
                    // action={{
                    //   type: "internal",
                    //   route: "pages/company/about-us",
                    //   color: "info",
                    //   label: "find out more",
                    // }}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <CourseContent sections={coursesData?.Sections} enrolled={enrolled} />
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default CourseDetails;
