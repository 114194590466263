/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import SimpleFooter from "examples/Footers/SimpleFooter";
import authApi from "../../../api/auth";
import AuthStorage from "../../../storage/storage";
import MKAlert from "components/MKAlert";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function SignInBasic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    const result = await authApi.login(email, password);

    if (result.status !== 200) {
      setError("Invalid email/password");
      console.log("error in signin", result);
      return;
    }
    setError("");

    AuthStorage.storeToken(result?.data?.jwt);
    window.location = "/program";
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <MKBox width="100%" position="absolute" zIndex={2} top="1rem">
        <MKTypography
          variant="h2"
          fontWeight="medium"
          color="white"
          mt={1}
          ml={2}
          textAlign="center"
        >
          Unlock Your Learning Journey{" "}
        </MKTypography>
      </MKBox>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${"https://storage.googleapis.com/truemerit-app/marketing%20videos/pexels-rafael-cerqueira-4737484.jpg"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <img
                  srcSet={
                    "https://trueguide.truemerit.io/uploads/logo_00198254e4.png?updated_at=2023-04-27T11:05:47.186Z"
                  }
                  src={
                    "https://trueguide.truemerit.io/uploads/logo_00198254e4.png?updated_at=2023-04-27T11:05:47.186Z"
                  }
                  alt={"truemerit-logo"}
                  loading="lazy"
                  style={{ width: "10rem", height: "2.4rem", objectFit: "contain" }}
                />
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput
                      type="email"
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2} style={{ position: "relative" }}>
                    <MKInput
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputAdornment
                      position="end"
                      style={{
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <IconButton
                        onClick={handleClickShowPassword}
                        style={{ position: "absolute", right: "0.8rem" }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                      sign in
                    </MKButton>
                  </MKBox>
                  {error ? (
                    <MKAlert color="error" sx={{ my: 2 }}>
                      {" "}
                      {error}
                    </MKAlert>
                  ) : null}
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter light />
      </MKBox>
    </>
  );
}

export default SignInBasic;
