/* eslint-disable curly */

import jwt_decode from "jwt-decode";

const key = "truemerit_lms_authToken";

const storeToken = async (authToken) => {
  try {
    localStorage.setItem(key, JSON.stringify(authToken));
  } catch (error) {
    console.log(error);
  }
};

const getToken = async () => {
  try {
    const value = localStorage.getItem(key);

    const item = JSON.parse(value);
    if (!item) return null;

    return item;
  } catch (error) {
    console.log(error);
  }
};

const removeToken = async () => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
};

const getUser = async () => {
  const token = await getToken();

  return token ? jwt_decode(token) : null;
};

export default {
  storeToken,
  getToken,
  removeToken,
  getUser,
};
