/* eslint-disable no-undef */

import { useState } from "react";

export default function useApi(apiFunc) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const request = async (...args) => {
    setLoading(true);
    const responce = await apiFunc(...args);

    setLoading(false);

    setError(responce.status !== 200);
    setData(responce.data);
    return responce;
  };

  return { data, loading, error, request };
}
