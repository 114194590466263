import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route, Navigate } from "react-router-dom";

import theme from "assets/theme";
import ProgramsDetails from "pages/LandingPages/ProgramsDetailScreen";
import SignInBasic from "pages/LandingPages/SignIn";
import CourseDetails from "pages/LandingPages/CourseDetailsScreen";
import CoursePlayer from "pages/LandingPages/CoursePlayerScreen";
import NotFound from "pages/LandingPages/NotFound"; // Import your Not Found page component
import AuthStorage from "./storage/storage";

export default function App() {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    restoreUser();
  }, []);

  const restoreUser = async () => {
    const result = await AuthStorage.getUser();

    if (result) {
      setUserId(result?.id);
    }
  };

  console.log("app js logged in", userId);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Routes>
          <Route path="/signin" element={<SignInBasic />} />
          {userId !== null ? ( // Check if userId is not null (initialized) before rendering routes
            <>
              <Route path="/" element={<ProgramsDetails />} />
              <Route path="/program" element={<ProgramsDetails />} />
              <Route path="/course/:courseId" element={<CourseDetails />} />
              <Route path="/lesson/:lessonId/:sections" element={<CoursePlayer />} />
              {/* Redirect to default route when authenticated */}
              <Route path="/" element={<Navigate to="/program" />} />
            </>
          ) : (
            // Redirect to Sign In page when not authenticated
            <>
              <Route path="/" element={<Navigate to="/signin" />} />
            </>
          )}
          {/* Not Found page */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}
