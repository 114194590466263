/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import MKButton from "components/MKButton/index";

function HorizontalTeamCard({
  image,
  name,
  position,
  description,
  buttonTitle,
  toRoute,
  buttonDisabled,
}) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: -6 }}>
          <MKBox width="100%" pt={2} pb={1} px={2}>
            <MKBox
              component="img"
              src={image}
              alt={name}
              width="100%"
              borderRadius="md"
              shadow="lg"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <MKTypography
              variant="h5"
              // sx={{ fontSize: { xs: 24, sm: name.length > 35 ? 16 : 17 } }}
              mb={0.5}
            >
              {name.length > 40 ? name.substring(0, 30) + "..." : name}
            </MKTypography>
            <MKTypography variant="h6" color={position.color} mb={1}>
              {position.label}
            </MKTypography>
            {description ? (
              <MKTypography variant="body2" color="text">
                {description}
              </MKTypography>
            ) : null}

            <MKButton
              component={Link}
              to={toRoute}
              variant="gradient"
              size="small"
              color={buttonDisabled ? "grey" : "info"}
              sx={{ mt: description ? 2 : 0 }}
              disabled={buttonDisabled}
            >
              {buttonTitle}
            </MKButton>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  toRoute: PropTypes.func,
  buttonDisabled: PropTypes.bool,
};

export default HorizontalTeamCard;
