/* eslint-disable curly */
/* eslint-disable dot-notation */

import { create } from "apisauce";

const apiClient = create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_STRAPI_URL,
});

export default apiClient;
