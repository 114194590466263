import client from "./client";

const endpoint = "/courses";
const endpointForPrograms = "/programs";
const endpointForSections = "/Sections";
const endpointsForLessons = "/Lessons";
// const endpointForTopics = "/Topics";
// const endpointForMarkComplete = "/user/me";
// const endpointForPhases = "/phases";

// const endpointForMarkCourseStarted = "/user/me";

// const endpointForPickUpWhereLeftOff = "/user/me";
// const endpointForAssignment = "/assignments";

const getPrograms = () => client.get(`${endpointForPrograms}/?populate=*`);
const getCourseById = (id) => client.get(`${endpoint}/${id}/?populate=*`);

// const getCourseById = (id) => client.get(`${endpoint}/?filters[Title][$eq	]=${id}&populate=*`);

const getLessonsById = (id) => client.get(`${endpointsForLessons}/${id}/?populate=*`);

//not used
// const getCourses = (pageNumber) =>
//   client.get(
//     `${endpoint}/?pagination[page]=${pageNumber}&pagination[pageSize]=10&populate=*`
//   );

// const getAllCoures = () => client.get(`${endpoint}/?populate=*`);

// const getFreeCourses = () =>
//   client.get(`${endpoint}/?filters[Course_Category][$eq]=Free&populate=*`);

// const getProgramsById = (id) =>
//   client.get(`${endpointForPrograms}/${id}/?populate=*`);

const getSectionsById = (id) => client.get(`${endpointForSections}/${id}/?populate=*`);

// const getTopicsById = (id) =>
//   client.get(`${endpointForTopics}/${id}/?populate=*`);

// const getPhaseById = (id) =>
//   client.get(`${endpointForPhases}/${id}/?populate=*`);

// const markTopicComplete = (topicId) =>
//   client.put(endpointForMarkComplete, { topics_completed: topicId });

// const markLessonComplete = (data) =>
//   client.put(endpointForMarkComplete, {
//     lessons_completed: data.lessons_completed,
//     course_id: data.course_id,
//     program_id: data.program_id,
//   });

// const markLessonWithNoProgramComplete = (data) =>
//   client.put(endpointForMarkComplete, {
//     lessons_completed: data.lessons_completed,
//     course_id: data.course_id,
//   });

// const markCourseStarted = (courseId) =>
//   client.put(endpointForMarkCourseStarted, {
//     courses_started: courseId,
//   });

// const getPickUpWhereLeftOff = () =>
//   client.get(`${endpointForPickUpWhereLeftOff}/?populate=*`);

// const getAssignmentsInLesson = (data) =>
//   client.get(
//     `${endpointForAssignment}/?filters[Lesson][id]=${data[0]}&filters[User][id]=${data[1]}&populate=*`
//   );

// const getAssignmentsInTopic = (data) =>
//   client.get(
//     `${endpointForAssignment}/?filters[Topic][id]=${data[0]}&filters[User][id]=${data[1]}&populate=*`
//   );

// const postAssignment = (data) => client.post(endpointForAssignment, data);

// const getSectionByLessonId = (id) =>
//   client.get(`${endpointForSections}/?filters[Lessons][id]=${id}&populate=*`);

// const getCourseBySectionId = (id) =>
//   client.get(`${endpoint}/?filters[Sections][id]=${id}&populate=*`);

// const getPhaseByCourseId = (id) =>
//   client.get(`${endpointForPhases}/?filters[Courses][id]=${id}&populate=*`);

// const getProgramByPhaseId = (id) =>
//   client.get(`${endpointForPrograms}/?filters[Phases][id]=${id}&populate=*`);

// const getProgramsByUserId = (userId) =>
//   client.get(`${endpointForPrograms}/?filters[users][id]=${userId}&populate=*`);

export default {
  //   getCourses,
  //   getAllCoures,
  //   getFreeCourses,
  getPrograms,

  //   getProgramsById,
  getCourseById,
  getSectionsById,
  getLessonsById,
  //   getTopicsById,
  //   getPhaseById,

  //   markTopicComplete,
  //   markLessonComplete,
  //   markLessonWithNoProgramComplete,
  //   markCourseStarted,

  //   getPickUpWhereLeftOff,
  //   getAssignmentsInLesson,
  //   getAssignmentsInTopic,
  //   postAssignment,

  //   getSectionByLessonId,
  //   getCourseBySectionId,
  //   getPhaseByCourseId,
  //   getProgramByPhaseId,
  //   getProgramsByUserId,
};
