/* eslint-disable curly */
/* eslint-disable dot-notation */

import { create } from "apisauce";
import authStorage from "../storage/storage";

const apiClient = create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_STRAPI_URL,
});

apiClient.addAsyncRequestTransform(async (request) => {
  const authToken = await authStorage.getToken();

  // console.log("client.js token", authToken);

  if (!authToken) return;

  request.headers["Authorization"] = `Bearer ${authToken}`;
});

export default apiClient;
