/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// Images

function CourseContent({ sections, enrolled }) {
  const [expanded, setExpanded] = React.useState("panel0");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      pt={6}
      pb={8}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      my={-2}
      sx={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}
    >
      <Container>
        <MKTypography variant="h3" color="white" sx={{ mb: 3 }}>
          Course Content{" "}
        </MKTypography>
        {sections?.data?.map((section, index) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={section?.id}
            sx={{
              borderTopRightRadius: index === 0 ? 10 : 0,
              borderBottomRightRadius: index === sections.data.length - 1 ? 10 : 0,

              borderTopLeftRadius: index === 0 ? 10 : 0,
              borderBottomLeftRadius: index === sections.data.length - 1 ? 10 : 0,
            }}
          >
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography>{section?.attributes?.Title}</Typography>
            </AccordionSummary>
            {section.Lessons.map((lesson) => (
              <AccordionDetails key={lesson.id}>
                <Link
                  to={enrolled ? `/lesson/${lesson.id}/${section.id}` : undefined}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography>{lesson?.Title}</Typography>
                </Link>
              </AccordionDetails>
            ))}
          </Accordion>
        ))}
      </Container>
    </MKBox>
  );
}

// Typechecking props for the SimpleFooter
CourseContent.propTypes = {
  sections: PropTypes.objectOf(PropTypes.string),
  enrolled: PropTypes.objectOf(PropTypes.bool),
};
export default CourseContent;
