// import client from "./client";

import clientWithoutJwt from "./clientWithoutJwt";

const endPoint = "/auth/local";
// const endPointForResetPassword = "/auth/change-password";

// const endPointForForgotPassword = "/auth/forgot-password-mobile";

// const endpointForNewPassword = "auth/reset-password";

const login = (identifier, password) => {
  console.log("hope", identifier, "\n", password);
  return clientWithoutJwt.post(endPoint, { identifier, password });
};
// const resetPassword = (changedPasswordInfo) => {
//   const data = new FormData();

//   data.append("currentPassword", changedPasswordInfo.currentPassword);
//   data.append("password", changedPasswordInfo.newPassword);
//   data.append("passwordConfirmation", changedPasswordInfo.reEnterNewPassword);

//   return client.post(endPointForResetPassword, data);
// };

// const requestResetPassword = (email) =>
//   client.post(endPointForForgotPassword, {
//     email,
//   });

// const setNewPassword = (info) =>
//   client.post(endpointForNewPassword, {
//     code: info.code,
//     password: info.newPassword,
//     passwordConfirmation: info.reEnterNewPassword,
//   });

export default {
  login,
  //   resetPassword,
  //   requestResetPassword,
  //   setNewPassword,
};
