/* eslint-disable no-undef */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images

function ProgramContent({ phases, enrolled }) {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      pt={6}
      pb={8}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      my={-2}
      sx={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}
    >
      {phases?.data.map((phase) => (
        <Container key={phase.id} sx={{ mb: 6 }}>
          <Grid container>
            <Grid item xs={12} md={12} sx={{ mb: 6 }}>
              <MKTypography variant="h3" color="white">
                {phase?.attributes?.Title}
              </MKTypography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {phase.Courses.map((course, index) => {
              const globalIndex =
                phases.data
                  .slice(0, phases.data.indexOf(phase) + 1)
                  .reduce((acc, cur) => acc + cur.Courses.length, 0) -
                phase.Courses.length +
                index +
                1;
              return (
                <Grid item xs={12} lg={6} sm={6} key={course.id}>
                  <MKBox mb={1}>
                    <HorizontalTeamCard
                      image={
                        process.env.REACT_APP_STRAPI_URL.replace("/api", "") +
                        course.Featured_Image.url
                      }
                      name={course.Title}
                      position={{ color: "info", label: `Week ${globalIndex}` }}
                      buttonTitle="View Course"
                      toRoute={enrolled ? `/course/${course.id}` : undefined}
                      // toRoute={
                      //   enrolled ? `/course/${course.Title.split(" ").join("-")}` : undefined
                      // }
                      buttonDisabled={enrolled ? false : true}
                    />
                  </MKBox>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      ))}
    </MKBox>
  );
}

// Typechecking props for the SimpleFooter
ProgramContent.propTypes = {
  phases: PropTypes.objectOf(PropTypes.string),
  enrolled: PropTypes.objectOf(ProgramContent.boolean),
};
export default ProgramContent;
