/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import "./markdown-coursePlayerScreen.css";

// About Us page sections
import CourseContent from "pages/LandingPages/CourseDetailsScreen/sections/CourseContent";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/courseDashboard.jpg";
import coursesApi from "../../../api/courses";
import useApi from "../../../hooks/useApi";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import { Skeleton, Stack } from "@mui/material";
import Markdown from "react-markdown";
import { useParams } from "react-router-dom";

import { create } from "apisauce";

function CoursePlayer() {
  const { lessonId, sections } = useParams();
  //console.log("sectionssss", sections);

  const sectionArray = JSON.parse(sections);

  const [contentId, setContentId] = useState(lessonId);

  // Now you have access to the lessons array

  const getLessonsApi = useApi(coursesApi.getLessonsById);

  const [h5pId, seth5pId] = useState("");

  const [arrayOfContent, setArrayOfContent] = useState();

  // console.log("array of content", arrayOfContent);

  useEffect(() => {
    getSectionsData();
  }, []);

  const getSectionsData = async () => {
    const sectionResult = await coursesApi.getSectionsById(sections);

    if (sectionResult.status === 200) {
      let arr = [];
      sectionResult?.data?.data?.attributes?.Lessons?.data.map((l) => {
        arr.push({ type: "lesson", id: l.id });
      });
      setArrayOfContent(arr);
    }
  };

  useEffect(() => {
    getLessonsApi.request(contentId);
    // temp(); //to be removed
  }, [contentId]);

  const lessonsData = getLessonsApi?.data?.data?.attributes;

  useEffect(() => {
    const h5p_Id = doesH5pTagExist(lessonsData?.Description);
    seth5pId(h5p_Id);
  }, [lessonsData]);

  function doesH5pTagExist(inputString) {
    // // Define a regular expression to match any [h5p id="..."] pattern
    // const h5pTagRegex = /\[h5p id="\d+"\]/;

    // // Use the test method to check if the pattern exists in the input string

    // return h5pTagRegex.test(inputString);

    const h5p_src_id = (lessonsData?.Description.match(/\[h5p id="(\d+)"\]/) || [])[1];

    return h5p_src_id;
  }

  const getVideoUrl = (videoProps) => {
    if (videoProps.mime === "video/youtube") {
      return videoProps.url;
    } else if (videoProps.rawData) {
      const html = videoProps.rawData.html;
      if (html) {
        return html.split("src=")[1].split(/[ >]/)[0].split(";")[0].substring(1);
      } else {
        return null;
      }
    } else {
      return videoProps.html.split("src=")[1].split(/[ >]/)[0].split(";")[0].substring(1);
    }
  };

  // console.log("lessonsss", lessonsData);

  const video_url =
    lessonsData?.Video !== "false" &&
    lessonsData?.Video !== null &&
    lessonsData?.Video !== undefined &&
    getVideoUrl(JSON.parse(lessonsData?.Video));

  function removeH5pTag(inputString) {
    // Define a regular expression to match the [h5p id="..."] pattern
    const h5pTagRegex = /\[h5p id="\d+"\]/g;

    // Replace all occurrences of the pattern with an empty string
    const resultString = inputString.replace(h5pTagRegex, "");

    return resultString;
  }

  // console.log("videoo irlll", video_url);

  // console.log("h5pp id", h5pId);

  //  console.log("materialll", lessonsData?.Material?.data);

  const handlePrevClick = () => {
    // console.log("contentt id", contentId);
    const findIndex = arrayOfContent.findIndex((a) => String(a.id) === String(contentId));

    // console.log("prev", findIndex);
    if (findIndex !== 0) {
      setContentId(arrayOfContent[findIndex - 1].id);
    } else {
      alert("You are already at the beginning of the content.");
    }
  };
  const handleNextClick = () => {
    // console.log("contentt id", contentId);
    const findIndex = arrayOfContent.findIndex((a) => String(a.id) === String(contentId));

    // console.log("next", findIndex);
    if (arrayOfContent.length - 1 !== findIndex) {
      setContentId(arrayOfContent[findIndex + 1].id);
    } else {
      alert("You have reached the end of the content.");
    }
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="60vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            {getLessonsApi.loading ? (
              <>
                <Skeleton
                  sx={{ bgcolor: "grey.900", mb: 2 }}
                  variant="rectangular"
                  color="white"
                  width={"60vw"}
                  height={"5vh"}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.900", mb: 2 }}
                  variant="rectangular"
                  color="white"
                  width={"40vw"}
                  height={"5vh"}
                />
              </>
            ) : (
              <MKTypography
                variant="h3"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3x1"],
                  },
                  fontSize: { xs: 32, sm: lessonsData?.Title?.length > 90 ? 30 : 40 },
                })}
                mb={3}
              >
                {lessonsData?.Title}
              </MKTypography>
            )}

            <Stack flexDirection="row" alignItems="center" justifyContent="center">
              <MKButton
                color="default"
                sx={{ color: ({ palette: { dark } }) => dark.main, mr: 2 }}
                onClick={handlePrevClick}
              >
                Previous
              </MKButton>
              <MKButton
                color="default"
                sx={{ color: ({ palette: { dark } }) => dark.main }}
                onClick={handleNextClick}
              >
                Next
              </MKButton>
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" my={4}>
          <Container>
            {getLessonsApi.loading === true ? (
              <Grid container spacing={2}>
                {[...Array(3)].map((_, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Card sx={{ maxWidth: 400 }}>
                      <Skeleton animation="wave" variant="circular" width={40} height={40} />
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton animation="wave" height={10} width="40%" />
                      <Skeleton sx={{ height: "60vh" }} animation="wave" variant="rectangular" />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : null}
            {video_url ? (
              <div style={{ position: "relative", paddingTop: "56.25%", height: 0 }}>
                <iframe
                  title="Video Player"
                  src={video_url}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%", // Default height for mobile
                    maxHeight: "80%", // Maximum height for web
                    border: "0",
                  }}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}

            {h5pId ? (
              <iframe
                title="H5P Content"
                src={`https://truemerit.io/wp-admin/admin-ajax.php?action=h5p_embed&id=${h5pId}`}
                width="100%" // Changed width to 100%
                height="600px" // Set a fixed height for better visibility
                frameBorder="0" // Use frameBorder instead of frameborder
                allowFullScreen // Simplified allowfullscreen attribute
              ></iframe>
            ) : null}

            {lessonsData?.Material?.data !== null &&
            lessonsData?.Material?.data[0].attributes?.mime === "application/pdf" ? (
              <div style={{ margin: "20px 0" }}>
                <a
                  href={
                    process.env.REACT_APP_STRAPI_URL.replace("/api", "") +
                    lessonsData?.Material?.data[0].attributes?.url
                  }
                  style={{ display: "block" }}
                >
                  {lessonsData?.Material?.data[0].attributes?.name}
                </a>
              </div>
            ) : null}

            {lessonsData?.Description ? (
              <div className="markdown-container">
                <Markdown>{removeH5pTag(lessonsData?.Description)}</Markdown>
              </div>
            ) : null}
          </Container>
        </MKBox>
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default CoursePlayer;
